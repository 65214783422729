<template>
	<div class="flex justify-between mb-3">
		<v-button @click="back" class="btn btn-small btn-default"
			><span v-html="back_icon"></span> Back to project</v-button
		>
	</div>
	<div class="heading heading-big">
		{{ prospects_data.name }}
	</div>

	<Tabs>
		<!-- <Tab name="Overview" :selected="true">
            <p>Test</p>
        </Tab> -->
		<Tab name="Prospects" :selected="true">
			<div class="lg:w-10/12">
				<div class="flex flex-row-reverse mb-3">
					<v-button
						v-if="activeTeamPermission != 'READ'"
						@click="addProspects"
						class="btn btn-small btn-add-new h-10"
						>Add prospects<span class="ml-3" v-html="plus_icon"></span
					></v-button>
					<v-button @click="exportProspects" class="btn btn-small btn-action-outline h-10 mr-3"
						>Export list <span v-html="download_icon" class="ml-3"></span
					></v-button>
				</div>
				<ProspectTable
					:teamId="teamId"
					:projectId="projectId"
					:listId="listId"
					:prospects_data="prospects_data"
					:key="prospectTableComponentKey"
					@deleteProspect="deleteProspectModal" />
			</div>
		</Tab>
	</Tabs>

	<Modal v-show="confirmDeleteModal" :widthPercent="50" @close="confirmDeleteModal = false">
		<template v-slot:header>
			<h3>Are you sure you want to delete this prospect?</h3>
		</template>

		<template v-slot:body>
			<div class="my-4">
				Name: <span class="font-bold text-md">{{ deleteProspectId }}</span>
			</div>
			<div class="flex">
				<v-button @click="cancelDeleteProspect" class="btn btn-default mr-3">Cancel</v-button>
				<v-button @click="confirmDeleteProspect" :loading="deletingProspect" class="btn btn-danger"
					>Delete</v-button
				>
			</div>
		</template>
	</Modal>
</template>

<script>
import { crudable } from "@/mixins/Crudable";
import Button from "@/components/ui/Button";
import ProspectTable from "@/components/projects/prospects/ProspectTable";
import Tab from "@/components/ui/Tab";
import Tabs from "@/components/ui/Tabs";
import Modal from "@/components/Modal";
import { mapGetters } from "vuex";
import moment from "moment";
import fsisearchapi from "@/services/api-clients/fsisearch";
import fsiprofessorapi from "@/services/api-clients/fsiprofessor";

// import tmp_data_cache from '@/tmp-data-cache.json'

const back_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                    </svg>`;
const plus_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>`;
const download_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                            </svg>`;

export default {
	// mixins: [crudable],

	created() {},

	mounted() {
		this.getProspectList();
	},

	components: {
		"v-button": Button,
		ProspectTable,
		Tab,
		Tabs,
		Modal,
	},

	props: ["teamId", "projectId", "listId"],

	data() {
		return {
			prospects_data: {},
			// confirmDeleteModal: false,
			// deletingProject: false,
			// deleteName: null,
			// deleteError: null,
			back_icon,
			plus_icon,
			download_icon,
			prospectTableComponentKey: 0,
			confirmDeleteModal: false,
			deleteProspectId: null,
			// deleteProspectName: '',
			deletingProspect: false,
		};
	},

	methods: {
		deleteProspectModal(prospectId) {
			this.confirmDeleteModal = true;
			this.deleteProspectId = prospectId;
			// this.deleteProspectName = name;
		},
		cancelDeleteProspect() {
			this.confirmDeleteModal = false;
			this.deleteProspectId = null;
		},
		async confirmDeleteProspect() {
			this.deletingProspect = true;
			await this.deleteProspect(this.deleteProspectId);
			this.deletingProspect = false;
			this.confirmDeleteModal = false;
			this.deleteProspectId = null;
			// this.deleteProspectName = '';
		},
		async deleteProspect(prospectId) {
			try {
				let response = await this.$api
					.resource("prospectProjectProspectList", {
						team_id: this.teamId,
						project_id: this.projectId,
						list_id: this.listId,
					})
					.delete(prospectId);
				this.getProspectList();
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
		},

		addProspects() {
			this.$router.push({
				name: "workspace-prospect-search",
				query: {
					projectId: this.projectId,
					listId: this.listId,
				},
			});
		},
		back() {
			this.$router.push({
				name: "prospects-project",
				params: { teamId: this.teamId, projectId: this.projectId },
			});
		},

		async getProspectList() {
			try {
				let response = await this.$api
					.resource("prospectProjectLists", {
						team_id: this.teamId,
						project_id: this.projectId,
					})
					.fetchOne(this.listId);
				this.prospects_data = response.data;
				this.prospectTableComponentKey += 1;
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
		},

		async getProspectInfo(domains) {
			let fsi_prof_response = await fsiprofessorapi.searchDomains(domains);
			let fsi_prof_data = fsi_prof_response.data.domain_entities;
			return fsi_prof_data;
		},

		async exportProspects() {
			let domains = [];
			for (let i in this.prospects_data.prospects) {
				domains.push(this.prospects_data.prospects[i].prospect_id);
			}

			let _domain_entities = await this.getProspectInfo(domains);
			// console.log(_prospectsInfo)

			// Flatten the list of domain entities
			let domain_entities = [];
			let keys = {};
			for (var i in _domain_entities) {
				let flattened_de = {};
				flattened_de["domain"] = _domain_entities[i].domain;
				keys["domain"] = "domain";
				// console.log(_domain_entities[i].latest_data_points)
				for (var j in _domain_entities[i].latest_data_points) {
					for (var k in _domain_entities[i].latest_data_points[j].payload) {
						let _k = _domain_entities[i].latest_data_points[j].data_type + "_" + k;
						if (_k == "innovateuk_grants-awarded") {
							continue;
						}
						keys[_k] = _k;
						flattened_de[_k] = _domain_entities[i].latest_data_points[j].payload[k];
					}
				}
				domain_entities.push(flattened_de);
			}

			// sort by name
			let name_key = "company-info_name";
			domain_entities.sort(function (a, b) {
				var nameA = a[name_key] ? a[name_key].toUpperCase() : a.domain.toUpperCase(); // ignore upper and lowercase
				var nameB = b[name_key] ? b[name_key].toUpperCase() : b.domain.toUpperCase(); // ignore upper and lowercase
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				// names must be equal
				return 0;
			});

			// Write the CSV
			keys = Object.keys(keys);
			let keys_string = keys.join(",");
			let csvContent = "data:text/csv;charset=utf-8," + keys_string + "\r\n";

			domain_entities.forEach(function (entity) {
				let row = [];
				for (var k in keys) {
					let value = "";
					if (keys[k] == "innovateuk_grants-awarded") {
						continue;
					}
					if (entity[keys[k]] != null) {
						value = String(entity[keys[k]]).replaceAll(",", "");
					}
					row.push(value);
				}
				csvContent += row.join(",") + "\r\n";
			});

			var encodedUri = encodeURI(csvContent);
			var link = document.createElement("a");
			link.setAttribute("href", encodedUri);
			let filename =
				"FSI-Export " +
				this.prospects_data.name +
				" " +
				this.listId +
				" " +
				moment().format("YYYYMMDDHHmmss") +
				".csv";
			link.setAttribute("download", filename);
			document.body.appendChild(link); // Required for FF

			link.click(); // This will download the data file named "my_data.csv".
		},
	},

	watch: {
		// resourceStatus(status) {
		//     if (status.status == 'deleted') {
		//         this.$router.push({
		//             name: 'team',
		//             params: {resourceId: this.teamId, deletedProjectId: this.resourceId}
		//         });
		//     } else if (status.status == 'delete_error') {
		//         this.deletingProject = false;
		//         this.deleteError = this.errorResource.title;
		//     }
		// },
		$route(to, from) {
			// console.log(to, from)
			this.confirmDeleteModal = false;
		},
	},

	computed: {
		...mapGetters(["activeTeamPermission"]),
	},
};
</script>

<style></style>
\]
