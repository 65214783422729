<!-- This example requires Tailwind CSS v2.0+ -->
<template>
	<div class="flex flex-col">
		<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
			<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
				<div class="px-3 pb-3 font-bold text-sm">Showing {{ formattedProspects.length }} companies</div>
				<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
					<table class="min-w-full divide-y divide-gray-200">
						<thead class="bg-gray-50">
							<tr>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Name
								</th>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Website
								</th>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Time added
								</th>
								<th scope="col" class="relative px-6 py-3"></th>
							</tr>
						</thead>
						<tbody class="bg-white divide-y divide-gray-200">
							<tr
								v-for="item in formattedProspects"
								:key="item.prospect_id"
								class="relative hover:bg-gray-100 cursor-pointer"
								@click="viewProspect(item.prospect_id)">
								<td class="px-6 py-4 break-normal max-w-md">
									<!-- <div class="text-sm text-gray-900">{{ item.info.name }}</div> -->
									<div class="break-normal text-sm text-gray-900 font-bold">
										{{
											item.info.name
												? item.info.name.toUpperCase()
												: item.info.domain.toUpperCase()
										}}
										<span class="text-xs ml-2 font-normal">{{
											checkLocationLetterCase(item.info.region)
										}}</span>
									</div>
									<div class="text-xs mt-2">
										{{ checkIndustryLetterCase(item.info.industry) }}
									</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="text-sm text-gray-900">
										<!-- <a class="underline hover:no-underline text-blue-600" :href="'http://' + item.info.domain" target="_blank">{{ item.info.domain }}</a> -->
										<a
											class="underline hover:no-underline text-blue-600"
											@click.stop="visitLink(item.info.domain)"
											:href="'http://' + item.info.domain"
											target="_blank"
											>{{ item.info.domain }}</a
										>
									</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="text-sm text-gray-900">
										{{ item.formatted_timestamp }}
									</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
									<button
										v-if="activeTeamPermission != 'READ'"
										@click.stop="deleteProspect(item.prospect_id)"
										class="btn btn-danger-outline btn-small mr-2">
										Delete
									</button>
									<!-- <button @click="viewProspect(item.prospect_id)" class="btn btn-default-outline btn-small">View</button> -->
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<svg
			v-if="loading"
			class="animate-spin ml-3 mt-3 h-10 w-10 text-black self-center"
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24">
			<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
			<path
				class="opacity-75"
				fill="currentColor"
				d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
		</svg>
	</div>
</template>

<script>
import fsisearchapi from "@/services/api-clients/fsisearch";
import moment from "moment";
import { mapGetters } from "vuex";
import { checkLocationLetterCase, checkIndustryLetterCase } from "@/utils";

export default {
	created() {},

	mounted() {
		this.loadProspects();
	},

	props: ["teamId", "projectId", "listId", "prospects_data"],

	emits: ["deleteProspect"],

	data() {
		return {
			loading: false,
			formattedProspects: [],
			checkLocationLetterCase,
			checkIndustryLetterCase,
		};
	},

	methods: {
		viewProspect(prospectId) {
			this.$router.push({
				name: "view-project-list-prospect",
				params: {
					teamId: this.teamId,
					projectId: this.projectId,
					listId: this.listId,
					prospectId: prospectId,
				},
			});
		},

		visitLink(domain) {
			return;
		},

		deleteProspect(prospect_id) {
			this.$emit("deleteProspect", prospect_id);
		},

		async getProspectInfo(domains) {
			let parameters = {
				domain_filter: domains,
			};
			let response = await fsisearchapi.executeSearch(this.queryString, parameters, domains.length);
			return response.data.hits;
		},

		async loadProspects() {
			let domains = [];
			for (let i in this.prospects_data.prospects) {
				domains.push(this.prospects_data.prospects[i].prospect_id);
			}

			let _prospectsInfo = await this.getProspectInfo(domains);
			let prospectsInfo = {};
			for (let i in _prospectsInfo) {
				prospectsInfo[_prospectsInfo[i].domain] = _prospectsInfo[i];
			}

			let _formattedProspects = [];
			for (let i in this.prospects_data.prospects) {
				let formattedProspect = {
					prospect_id: this.prospects_data.prospects[i].prospect_id,
					formatted_timestamp: moment(this.prospects_data.prospects[i].timestamp).format(
						"ddd, MMM Do YYYY, HH:mm:ss"
					),
					info: prospectsInfo[this.prospects_data.prospects[i].prospect_id],
				};
				_formattedProspects.push(formattedProspect);
			}

			// sort by name
			_formattedProspects.sort(function (a, b) {
				var nameA = a.info.name ? a.info.name.toUpperCase() : a.info.domain.toUpperCase(); // ignore upper and lowercase
				var nameB = b.info.name ? b.info.name.toUpperCase() : b.info.domain.toUpperCase(); // ignore upper and lowercase
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				// names must be equal
				return 0;
			});

			this.formattedProspects = _formattedProspects;
		},
	},

	computed: {
		...mapGetters(["activeTeamPermission"]),
	},
};
</script>
